<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="ihaveapp">
        <heade></heade>
        <zongqiu class="xuanfu" v-drag></zongqiu>
        <div class="con">
            <div class="con_tit">
                <input type="text" v-model="guanjian_zi" placeholder="请输入搜索关键字">
                <p @click="keyu">搜索</p>
            </div>
            <div class="con_xuanze_shenpi">
                审批类型
				<el-select v-model="leixing_name" @change="dianji_xuanze">
					<el-option
					v-for="(i,index) in list"
					:key="index"
					:label="i.flow_name"
					:value="i.flow_id">
					</el-option>
				</el-select>
                <ul>
                    <li @click="dianji_qiehuan(index)" :style="dangqian==index?'color:#9a86db;border:0.01rem solid #9a86db;':''" v-for='(i,index) in shenpi_zhuangtai' :key="index">{{i}}</li>
                </ul>
            </div>
			<ul class="ul1">
				<li class="li">
					<ul class="li_tit">
						<li>审批标题</li>
						<li>客商名称</li>
						<li>审批摘要</li>
						<li>发起时间</li>
						<li>完成时间</li>
						<li>状态</li>
					</ul>
				</li>
			</ul>
			<ul class="ul">
				<li class="li2" v-for="i in shenpi_con" :key="i.flow_no" @click="dianji_xiangqing(i)">
					<ul class="li_tit">
						<li>
                            <!-- <img :src="i.img" alt=""> -->
                        {{i.name}}</li>
                        <li>{{i.cust_name}}
							<span v-if="i.flow_id=='7TvJHPyovM'&&(i.edit_flag==1||i.del_flag==1)">{{i.del_flag==1?'客户已删除':'客户已修改'}}</span>
							<span v-if="i.flow_id=='5FotYgnu0Z'&&(i.edit_flag==1||i.del_flag==1)">{{i.del_flag==1?'供应商已删除':'供应商已修改'}}</span>
							<span v-if="i.flow_id=='PLOQ9ku3Ip'&&(i.edit_flag==1||i.del_flag==1)">{{i.del_flag==1?'单据已删除':'单据已修改'}}</span>
							<span v-if="i.void_flag==1">单据已作废</span>
						</li>
						<li>{{i.flow_desc}}</li>
						<li>{{i.start_time}}</li>
						<li>{{i.appr_time}}</li>
						<li>{{i.caozuo}}</li>
					</ul>
				</li>
			</ul>
        </div>
		<div class="youce_xianshi" v-if="dianji_chuxian_youce" @click="dianji_chuxian_youce=false">
			<div class="youce_box" @click.stop>
				<p class="youce_box_tit">
					{{xiangqing.tit}}
					<span @click="dianji_chuxian_youce=false">X</span>
				</p>
				<div class="youce_box_touxiang">
					<div class="youce_box_touxiang_left">
						<!-- <img :src="xiangqing.img" alt=""> -->
						<p class="toux">{{xiangqing.touxiang_text}}
						</p>
						<div class="youce_box_touxiang_left_text">
							<p>{{xiangqing.name}}
								<span style="font-size:10px;background:rgb(230, 242, 254);color:rgb(50, 150, 250);padding:0.02rem;" v-if="flag_replace_write==1">{{replace_write_user_name+'代提交'}}</span>
							</p>
							<p>{{gai_shei_shenpi(dangqian_shenpi_ren)}}</p>
						</div>
					</div>
					<div class="youce_box_touxiang_right">
						<el-tooltip class="item" effect="dark" content="归档单据查看" placement="bottom">
							<!-- <i class='el-icon-view' @click="dakai_danju(xiangxi_xinxi)"></i> -->
							<img v-if="(yanjing_zhanshi&&xiangqing.flow_id=='7TvJHPyovM')||
								xiangqing.flow_id=='Z7VDOK9HGu'||
								xiangqing.flow_id=='453y6pLD52'||
								(yanjing_zhanshi&&xiangqing.flow_id=='PLOQ9ku3Ip')||
								xiangqing.flow_id=='Eb3n7CKQzi'||
								xiangqing.flow_id=='qIwxmsMTgv'||
								xiangqing.flow_id=='VMCizuVOXb'||
								xiangqing.flow_id=='He5ln0bdDO'||
								xiangqing.flow_id=='cvRyWGiUQH'||
								xiangqing.flow_id=='GBh7N2jMsi'||
								xiangqing.flow_id=='H49d4edc3T'||
								yanjing_zhanshi"  src="../assets/me/eye.png" @click="dakai_danju(xiangxi_xinxi)" alt="">
						</el-tooltip>
						<!-- <el-tooltip class="item" effect="dark" content="提示文字" placement="bottom">
							<i class='el-icon-document'></i>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="打印" placement="bottom">
							<i class='el-icon-printer'></i>
						</el-tooltip> -->
						<el-tooltip class="item" v-if="xiangxi_xinxi.length!=0" effect="dark" content="下载" placement="bottom">
							<i class='el-icon-download'></i>
							<i class='el-icon-download' @click="dianji_xiazai_danju"></i>
						</el-tooltip>
					</div>
				</div>
				<div class="youce_xiangxi_xinxi" v-if="xiangxi_xinxi.length!=0">
					<p class="xiangxi_tit" @click="xiangqing_zhankai=!xiangqing_zhankai">详细信息<i :class="xiangqing_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
					<transition name="el-zoom-in-top">
						<div v-show="xiangqing_zhankai" class="transition-box">
							<!-- {{xiangxi_xinxi}} -->
							<!-- hXstgwxhzh  出库单    vY9ubhTO89  付款单   5FotYgnu0Z  供应商录入单-->
							<p v-if="xiangxi_xinxi.cust_name!==undefined&&xiangqing.flow_id!='hXstgwxhzh'">客户名称：{{xiangxi_xinxi.cust_name}}</p>
							<p v-if="xiangxi_xinxi.file_no!==undefined&&xiangqing.flow_id!='hXstgwxhzh'">归档号：{{xiangxi_xinxi.file_no}}</p>
							<p v-if='xiangqing.flow_id!="fZ0x5qedzn"&&xiangqing.flow_id!="hXstgwxhzh"
								&&xiangqing.flow_id!="vY9ubhTO89"
								&&xiangqing.flow_id!="5FotYgnu0Z"
                &&xiangqing.flow_id!="AY3cli7BAb"&&
								xiangxi_xinxi.staff_name!==undefined'>负责人：{{xiangxi_xinxi.staff_name}}</p>
							<p v-if='xiangqing.flow_id=="fZ0x5qedzn"&&xiangxi_xinxi.staff_name!==undefined'>入库员：{{xiangxi_xinxi.staff_name}}</p>
							<!-- <p v-if='xiangqing.flow_id=="hXstgwxhzh"&&xiangxi_xinxi.staff_name!==undefined'>出库员：{{xiangxi_xinxi.staff_name}}</p> -->
							<p v-if="xiangxi_xinxi.dept_name!==undefined&&xiangqing.flow_id!='hXstgwxhzh'&&xiangqing.flow_id!='vY9ubhTO89'&&xiangqing.flow_id!='5FotYgnu0Z'&&xiangqing.flow_id!='AY3cli7BAb'">部门：{{xiangxi_xinxi.dept_name}}</p>
							<div v-if="xiangqing.flow_id=='7TvJHPyovM'">
								<ul>
									<li v-for='(i,index) in xiangxi_xinxi.contract_detail' :key="index">
										<p>决策人({{index+1}})</p>
										<p v-if="i.name!==undefined">姓名：{{i.name}}</p>
										<p v-if="i.sex!==undefined">性别：{{i.sex}}</p>
										<p v-if="i.dept!==undefined">部门：{{i.dept}}</p>
										<p v-if="i.post!==undefined">职务：{{i.post}}</p>
										<p v-if="i.fixed_phone!==undefined">座机：{{i.fixed_phone}}</p>
										<p v-if="i.mobile!==undefined">手机：{{i.mobile}}</p>
										<p v-if="i.birthday!==undefined">生日：{{i.birthday}}</p>
										<p v-if="i.interest!==undefined">爱好：{{i.interest}}</p>
										<p v-if="i.qq!==undefined">QQ：{{i.qq}}</p>
										<p v-if="i.wechat!==undefined">微信：{{i.wechat}}</p>
										<p v-if="i.email!==undefined">邮箱：{{i.email}}</p>
										<p v-if="i.card!==undefined">名片：<img @click="tupian_dianji(i.card)" class="tupian_kuang" :src="i.card" alt=""></p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.province!==undefined">省份：{{xiangxi_xinxi.province}}</p>
								<p v-if="xiangxi_xinxi.city!==undefined">城市：{{xiangxi_xinxi.city}}</p>
								<p v-if="xiangxi_xinxi.industry!==undefined">行业：{{xiangxi_xinxi.industry}}</p>
								<p v-if="xiangxi_xinxi.source!==undefined">客户来源：{{xiangxi_xinxi.source}}</p>
								<p v-if="xiangxi_xinxi.level!==undefined">客户等级：{{xiangxi_xinxi.level}}</p>
								<p v-if="xiangxi_xinxi.cat_name!==undefined">客户标签：{{xiangxi_xinxi.cat_name}}</p>
								<p v-if="xiangxi_xinxi.biz_addr!==undefined">经营地址：{{xiangxi_xinxi.biz_addr}}</p>
								<p v-if="xiangxi_xinxi.rel_p_com!==undefined">母公司名称：{{xiangxi_xinxi.rel_p_com}}</p>
								<p v-if="xiangxi_xinxi.register_addr!==undefined">注册地址：{{xiangxi_xinxi.register_addr}}</p>
								<p v-if="xiangxi_xinxi.legal_name!==undefined">法定代表人：{{xiangxi_xinxi.legal_name}}</p>
								<p v-if="xiangxi_xinxi.uscc!==undefined">营业执照号：{{xiangxi_xinxi.uscc}}</p>
								<p v-if="xiangxi_xinxi.register_capital!==undefined">注册资本：{{xiangxi_xinxi.register_capital}}</p>
								<p v-if="xiangxi_xinxi.register_date!==undefined">成立日期：{{xiangxi_xinxi.register_date}}</p>
								<p v-if="xiangxi_xinxi.biz_term!==undefined">营业期限：{{xiangxi_xinxi.biz_term}}</p>
								<p v-if="xiangxi_xinxi.biz_scope!==undefined">营业范围：{{xiangxi_xinxi.biz_scope}}</p>
								<p v-if="xiangxi_xinxi.biz_lic_pic!==undefined">营业执照：<img @click="tupian_dianji(xiangxi_xinxi.biz_lic_pic)" class="tupian_kuang" :src="xiangxi_xinxi.biz_lic_pic" alt=""></p>
							</div>
							<div v-if="xiangqing.flow_id=='Z7VDOK9HGu'">
								<p v-if="xiangxi_xinxi.visit_type!==undefined">拜访方式：{{xiangxi_xinxi.visit_type}}</p>
								<p v-if="xiangxi_xinxi.visit_time!==undefined">拜访时间：{{xiangxi_xinxi.visit_time}}</p>
								<p v-if="xiangxi_xinxi.contact!==undefined">决策人：{{xiangxi_xinxi.contact}}</p>
								<p v-if="xiangxi_xinxi.current_stage!==undefined">漏斗现阶段：{{jieduan_hanzi(xiangxi_xinxi.current_stage)}}</p>
								<p v-if="xiangxi_xinxi.next_stage!==undefined">预计下一漏斗阶段：{{jieduan_hanzi(xiangxi_xinxi.next_stage)}}</p>
								<p v-if="xiangxi_xinxi.remarks!==undefined">备注：{{xiangxi_xinxi.remarks}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='453y6pLD52'">
								<p v-if="xiangxi_xinxi.start_time!==undefined">开始时间：{{xiangxi_xinxi.start_time}}</p>
								<p v-if="xiangxi_xinxi.end_time!==undefined">结束时间：{{xiangxi_xinxi.end_time}}</p>
								<p v-if="xiangxi_xinxi.hour_long!==undefined">时长：{{xiangxi_xinxi.hour_long}}小时</p>
								<p v-if="xiangxi_xinxi.current_stage!==undefined">漏斗现阶段：{{jieduan_hanzi(xiangxi_xinxi.current_stage)}}</p>
								<p v-if="xiangxi_xinxi.funn_stage_change_flag!==undefined">漏斗阶段变化：{{xiangxi_xinxi.funn_stage_change_flag}}</p>
								<p v-if="xiangxi_xinxi.funn_stage!==undefined">漏斗达成阶段：{{jieduan_hanzi(xiangxi_xinxi.funn_stage)}}</p>
								<p v-if="xiangxi_xinxi.change_cause!==undefined">阶段变化原因：{{xiangxi_xinxi.change_cause}}</p>
								<p v-if="xiangxi_xinxi.remarks!==undefined">备注：{{xiangxi_xinxi.remarks}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='PLOQ9ku3Ip'">
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_detail" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.stage_funn!==undefined">漏斗阶段：{{jieduan_hanzi(xiangxi_xinxi.stage_funn)}}</p>
								<p v-if="xiangxi_xinxi.stage_before_back!=undefined&&xiangxi_xinxi.flag_back==1">退回前漏斗阶段：{{jieduan_hanzi(xiangxi_xinxi.stage_before_back)}}</p>
								<p v-if="xiangxi_xinxi.amt_funn!==undefined">漏斗金额：{{xiangxi_xinxi.amt_funn}}</p>
								<p v-if="xiangxi_xinxi.date_con_est!==undefined">预计合同日期：{{xiangxi_xinxi.date_con_est}}</p>
								<p v-if="xiangxi_xinxi.rate_funn_disc!==undefined">漏斗折算比例：{{xiangxi_xinxi.rate_funn_disc}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='Eb3n7CKQzi'">
								<p v-if="xiangxi_xinxi.help_order_flag!==undefined">是否为助单行为：{{xiangxi_xinxi.help_order_flag}}</p>
								<p v-if="xiangxi_xinxi.disp_staff_name!==undefined">派工人员：{{xiangxi_xinxi.disp_staff_name}}</p>
								<p v-if="xiangxi_xinxi.help_order_type!==undefined">助单类型：{{xiangxi_xinxi.help_order_type}}</p>
								<p v-if="xiangxi_xinxi.biz_trip_type!==undefined">出差类型：{{xiangxi_xinxi.biz_trip_type}}</p>
								<p v-if="xiangxi_xinxi.addr!==undefined">地址：{{xiangxi_xinxi.addr}}</p>
								<p v-if="xiangxi_xinxi.contact!==undefined">决策人：{{xiangxi_xinxi.contact}}</p>
								<p v-if="xiangxi_xinxi.mobile!==undefined">手机：{{xiangxi_xinxi.mobile}}</p>
								<p v-if="xiangxi_xinxi.trip_start_time!==undefined">开始时间：{{xiangxi_xinxi.trip_start_time}}</p>
								<p v-if="xiangxi_xinxi.trip_end_time!==undefined">结束时间：{{xiangxi_xinxi.trip_end_time}}</p>
								<p v-if="xiangxi_xinxi.hour_long!==undefined">时长：{{xiangxi_xinxi.hour_long}}小时</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_detail" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
										<p v-if="i.disp_item!==undefined">派工项目：{{i.disp_item}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.remarks!==undefined">备注：{{xiangxi_xinxi.remarks}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='qIwxmsMTgv'">
								<p v-if="xiangxi_xinxi.date_con!==undefined">合同日期：{{xiangxi_xinxi.date_con}}</p>
								<p v-if="xiangxi_xinxi.belong_to_organ!==undefined">所属公司：{{xiangxi_xinxi.belong_to_organ}}</p>
								<p v-if="xiangxi_xinxi.industry!==undefined">行业：{{xiangxi_xinxi.industry}}</p>
								<p v-if="xiangxi_xinxi.con_type!==undefined">合同类型：{{xiangxi_xinxi.con_type}}</p>
								<p v-if="xiangxi_xinxi.con_valid_term!==undefined">合同有效时间：{{xiangxi_xinxi.con_valid_term}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_list" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.amt_con!==undefined">合同金额：{{xiangxi_xinxi.amt_con}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.amt_list" :key="index">
										<p>开票({{index+1}})</p>
										<p v-if="i.date_inv_est!==undefined">预计发票日期：{{i.date_inv_est}}</p>
										<p v-if="i.amt_inv_est!==undefined">预计发票金额：{{i.amt_inv_est}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.amt_con!==undefined">预计发票金额合计：{{xiangxi_xinxi.amt_con}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='VMCizuVOXb'">
								<p v-if="xiangxi_xinxi.belong_to_organ!==undefined">所属公司：{{xiangxi_xinxi.belong_to_organ}}</p>
								<p v-if="xiangxi_xinxi.date_inv!==undefined">发票日期：{{xiangxi_xinxi.date_inv}}</p>
								<p v-if="xiangxi_xinxi.inv_type!==undefined">发票类型：{{xiangxi_xinxi.inv_type}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_list" :key="index">
										<p>发票内容({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
										<p v-if="i.price_unit_no_tax!==undefined">不含税折后单价：{{i.price_unit_no_tax}}</p>
										<p v-if="i.amt_no_tax!==undefined">不含税金额：{{i.amt_no_tax}}</p>
										<p v-if="i.rate_tax!==undefined">税率：{{i.rate_tax}}%</p>
										<p v-if="i.amt_tax!==undefined">税额：{{i.amt_tax}}</p>
										<p v-if="i.amt_inv_detail!==undefined">发票金额：{{i.amt_inv_detail}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.amt_no_tax_sum!==undefined">合计不含税金额：{{xiangxi_xinxi.amt_no_tax_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_tax_sum!==undefined">合计税额：{{xiangxi_xinxi.amt_tax_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_inv!==undefined">合计发票金额：{{xiangxi_xinxi.amt_inv}}</p>
								<p v-if="xiangxi_xinxi.amt_inv_sum!==undefined">累计发票金额：{{xiangxi_xinxi.amt_inv_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_inv_un!==undefined">合同未开发票金额：{{xiangxi_xinxi.amt_inv_un}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.amt_list" :key="index">
										<p>回款({{index+1}})</p>
										<p v-if="i.date_rec_est!==undefined">预计回款日期：{{i.date_rec_est}}</p>
										<p v-if="i.amt_rec_est!==undefined">预计回款金额：{{i.amt_rec_est}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.cust_name!==undefined">客户：{{xiangxi_xinxi.cust_name}}</p>
								<p v-if="xiangxi_xinxi.tax_no!==undefined">纳税识别号：{{xiangxi_xinxi.tax_no}}</p>
								<p v-if="xiangxi_xinxi.mobile!==undefined">电话：{{xiangxi_xinxi.mobile}}</p>
								<p v-if="xiangxi_xinxi.addr!==undefined">地址：{{xiangxi_xinxi.addr}}</p>
								<p v-if="xiangxi_xinxi.bank!==undefined">开户行：{{xiangxi_xinxi.bank}}</p>
								<p v-if="xiangxi_xinxi.bank_acct!==undefined">开户行账号：{{xiangxi_xinxi.bank_acct}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='He5ln0bdDO'">
								<p v-if="xiangxi_xinxi.date_rec!==undefined">回款日期：{{xiangxi_xinxi.date_rec}}</p>
								<p v-if="xiangxi_xinxi.inv_code!==undefined">发票代码：{{xiangxi_xinxi.inv_code}}</p>
								<p v-if="xiangxi_xinxi.inv_no!==undefined">发票号码：{{xiangxi_xinxi.inv_no}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_detail" :key="index">
										<p>产品回款({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
							  <template v-if="xiangxi_xinxi.pay_detail!==undefined">
                   <li v-for="(item,index) in xiangxi_xinxi.pay_detail" :key="index" style="padding-left:0.2rem;color:#888888;line-height:0.32rem;">结算方式：{{item.pay_type}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;结算金额：{{item.amt_pay}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;结算占比%：{{item.rate_pay}}</li>
                </template>
                <p v-if="xiangxi_xinxi.list_pay!==undefined">结算方式：{{xiangxi_xinxi.list_pay}}</p>
                <p v-if="xiangxi_xinxi.mc_level!==undefined">会员等级：{{xiangxi_xinxi.mc_level === 'null'? '-':xiangxi_xinxi.mc_level}}</p>
                <p v-if="xiangxi_xinxi.mc_discount!==undefined">会员折扣%：{{xiangxi_xinxi.mc_discount === 'null'? '-':xiangxi_xinxi.mc_discount}}</p>
                <p v-if="xiangxi_xinxi.mc_status !==undefined">会员状态：{{xiangxi_xinxi.mc_status === '1' ? '正常':'不正常'}}</p>
                <p v-if="xiangxi_xinxi.mc_valid_date !==undefined">会员截止日：{{xiangxi_xinxi.mc_valid_date}}</p>
								<p v-if="xiangxi_xinxi.amt_rec!==undefined">回款金额：{{xiangxi_xinxi.amt_rec}}</p>
                <p v-if="xiangxi_xinxi.amt_end_mc!==undefined">会员卡余额：{{xiangxi_xinxi.amt_end_mc}}</p>
								<p v-if="xiangxi_xinxi.amt_rec_sum!==undefined">累计回款金额：{{xiangxi_xinxi.amt_rec_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_inv_sum!==undefined">累计开票金额：{{xiangxi_xinxi.amt_inv_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_rec_unrec!==undefined">应收金额：{{xiangxi_xinxi.amt_rec_unrec}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='cvRyWGiUQH'">
								<p v-if="xiangxi_xinxi.start_time!==undefined">开始时间：{{xiangxi_xinxi.start_time}}</p>
								<p v-if="xiangxi_xinxi.end_time!==undefined">结束时间：{{xiangxi_xinxi.end_time}}</p>
								<p v-if="xiangxi_xinxi.hour_long!==undefined">时长：{{xiangxi_xinxi.hour_long}}小时</p>
								<p v-if="xiangxi_xinxi.go_out_cause!==undefined">外出事由：{{xiangxi_xinxi.go_out_cause}}</p>
								<p v-if="xiangxi_xinxi.year_hour_long!==undefined">年累计外出时长：{{xiangxi_xinxi.year_hour_long}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='GBh7N2jMsi'">
								<p v-if="xiangxi_xinxi.remarks!==undefined">出差备注：{{xiangxi_xinxi.remarks}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.trip_detail" :key="index">
										<p>行程({{index+1}})</p>
										<p v-if="i.transport!==undefined">交通工具：{{i.transport}}</p>
										<p v-if="i.leave_city!==undefined">出发城市：{{i.leave_city}}</p>
										<p v-if="i.arrive_city!==undefined">目的城市：{{i.arrive_city}}</p>
										<p v-if="i.start_time!==undefined">开始时间：{{i.start_time}}{{i.start_noon==1?'下午':'上午'}}</p>
										<p v-if="i.end_time!==undefined">结束时间：{{i.end_time}}{{i.end_noon==1?'下午':'上午'}}</p>
										<p v-if="i.hour_long!==undefined">时长（天）：{{i.hour_long}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.trip_days!==undefined">出差天数：{{xiangxi_xinxi.trip_days}}</p>
								<p v-if="xiangxi_xinxi.year_trip_days!==undefined">年累计出差天数：{{xiangxi_xinxi.year_trip_days}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='H49d4edc3T'">
								<p v-if="xiangxi_xinxi.date_deliver!==undefined">日期：{{xiangxi_xinxi.date_deliver}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_detail" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.amt_deliver!==undefined">交付金额：{{xiangxi_xinxi.amt_deliver}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='5FotYgnu0Z'"><!--供应商录入单-->
								<p v-if="xiangxi_xinxi.supply_name!==undefined">供应商名称：{{xiangxi_xinxi.supply_name}}</p>
								<p v-if="xiangxi_xinxi.staff_name!==undefined">负责人：{{xiangxi_xinxi.staff_name}}</p>
								<p v-if="xiangxi_xinxi.dept_name!==undefined">部门：{{xiangxi_xinxi.dept_name}}</p>
								<ul>
									<li v-for='(i,index) in xiangxi_xinxi.supply_detail' :key="index">
										<p>联系人({{index+1}})</p>
										<p v-if="i.name!==undefined">姓名：{{i.name}}</p>
										<p v-if="i.sex!==undefined">性别：{{i.sex}}</p>
										<p v-if="i.dept!==undefined">部门：{{i.dept}}</p>
										<p v-if="i.post!==undefined">职务：{{i.post}}</p>
										<p v-if="i.fixed_phone!==undefined">座机：{{i.fixed_phone}}</p>
										<p v-if="i.mobile!==undefined">手机：{{i.mobile}}</p>
										<p v-if="i.birthday!==undefined">生日：{{i.birthday}}</p>
										<p v-if="i.interest!==undefined">爱好：{{i.interest}}</p>
										<p v-if="i.qq!==undefined">QQ：{{i.qq}}</p>
										<p v-if="i.wechat!==undefined">微信：{{i.wechat}}</p>
										<p v-if="i.email!==undefined">邮箱：{{i.email}}</p>
										<p v-if="i.card!==undefined">名片：<img @click="tupian_dianji(i.card)" class="tupian_kuang" :src="i.card" alt=""></p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.province!==undefined">省份：{{xiangxi_xinxi.province}}</p>
								<p v-if="xiangxi_xinxi.city!==undefined">城市：{{xiangxi_xinxi.city}}</p>
								<p v-if="xiangxi_xinxi.industry!==undefined">行业：{{xiangxi_xinxi.industry}}</p>
								<p v-if="xiangxi_xinxi.source!==undefined">供应商来源：{{xiangxi_xinxi.source}}</p>
								<p v-if="xiangxi_xinxi.level!==undefined">供应商等级：{{xiangxi_xinxi.level}}</p>
								<p v-if="xiangxi_xinxi.tag_name!==undefined&&xiangxi_xinxi.tag_name.length!=0">供应商标签：{{xiangxi_xinxi.tag_name}}</p>
								<p v-if="xiangxi_xinxi.biz_addr!==undefined">经营地址：{{xiangxi_xinxi.biz_addr}}</p>
								<p v-if="xiangxi_xinxi.rel_p_com!==undefined">母公司名称：{{xiangxi_xinxi.rel_p_com}}</p>
								<p v-if="xiangxi_xinxi.register_addr!==undefined">注册地址：{{xiangxi_xinxi.register_addr}}</p>
								<p v-if="xiangxi_xinxi.legal_name!==undefined">法定代表人：{{xiangxi_xinxi.legal_name}}</p>
								<p v-if="xiangxi_xinxi.uscc!==undefined">纳税识别号：{{xiangxi_xinxi.uscc}}</p>
								<p v-if="xiangxi_xinxi.register_capital!==undefined">注册资本：{{xiangxi_xinxi.register_capital}}</p>
								<p v-if="xiangxi_xinxi.register_date!==undefined">成立日期：{{xiangxi_xinxi.register_date}}</p>
								<p v-if="xiangxi_xinxi.biz_term!==undefined">营业期限：{{xiangxi_xinxi.biz_term}}</p>
								<p v-if="xiangxi_xinxi.biz_scope!==undefined">经营范围：{{xiangxi_xinxi.biz_scope}}</p>
								<p v-if="xiangxi_xinxi.ent_nature!==undefined">企业性质：{{xiangxi_xinxi.ent_nature}}</p>
								<p v-if="xiangxi_xinxi.biz_lic_pic!==undefined&&xiangxi_xinxi.biz_lic_pic.length!=0">营业执照：<img @click="tupian_dianji(xiangxi_xinxi.biz_lic_pic)" class="tupian_kuang" :src="xiangxi_xinxi.biz_lic_pic" alt=""></p>
								<p v-if="xiangxi_xinxi.bank_name!==undefined">开户行名称：{{xiangxi_xinxi.bank_name}}</p>
								<p v-if="xiangxi_xinxi.bank_acct!==undefined">银行账号：{{xiangxi_xinxi.bank_acct}}</p>
								<p v-if="xiangxi_xinxi.open_acct_pic!==undefined&&xiangxi_xinxi.open_acct_pic.length!=0">开户许可证：<img @click="tupian_dianji(xiangxi_xinxi.open_acct_pic)" class="tupian_kuang" :src="xiangxi_xinxi.open_acct_pic" alt=""></p>
							</div>
							<div v-if="xiangqing.flow_id=='Xsk2pLBS9a'"><!--采购单-->
								<p v-if="xiangxi_xinxi.date_pur!==undefined">采购日期：{{xiangxi_xinxi.date_pur}}</p>
								<ul>
									<li v-for='(i,index) in xiangxi_xinxi.prod_list' :key="index">
										<p>采购单列表({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.qty_stock!==undefined">库存数量：{{i.qty_stock}}</p>
										<p v-if="i.supply_id!==undefined">供应商：{{i.supply_name}}</p>
										<p v-if="i.price_quotation!==undefined">产品报价：{{i.price_quotation}}</p>
										<p v-if="i.qty_pur!==undefined">采购数量：{{i.qty_pur}}</p>
										<p v-if="i.amt_pur!==undefined">采购金额：{{i.amt_pur}}</p>
										<p v-if="i.qty_stock_est!==undefined">预计库存：{{i.qty_stock_est}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.qty_pur_sum!==undefined">采购数量合计：{{xiangxi_xinxi.qty_pur_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_pur_sum!==undefined">采购金额合计：{{xiangxi_xinxi.amt_pur_sum}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='u1SCMMoUwK'"><!--采购发票单-->
								<p v-if="xiangxi_xinxi.date_pay!==undefined">发票日期：{{xiangxi_xinxi.date_pay}}</p>
								<p v-if="xiangxi_xinxi.supply_name!==undefined">供应商：{{xiangxi_xinxi.supply_name}}</p>
								<p v-if="xiangxi_xinxi.inv_type!==undefined">发票类型：{{fapiao_wenzi_zhuanhuan(xiangxi_xinxi.inv_type)}}</p>
								<p v-if="xiangxi_xinxi.inv_code!==undefined">发票代码：{{xiangxi_xinxi.inv_code}}</p>
								<p v-if="xiangxi_xinxi.inv_no!==undefined">发票号码：{{xiangxi_xinxi.inv_no}}</p>
								<ul>
									<li v-for='(i,index) in xiangxi_xinxi.prod_list' :key="index">
										<p>列表({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.supply_id!==undefined">供应商：{{i.supply_name}}</p>
										<p v-if="i.price_quotation!==undefined">产品报价：{{i.price_quotation}}</p>
										<p v-if="i.qty_pur!==undefined">采购数量：{{i.qty_pur}}</p>
										<p v-if="i.amt_inv!==undefined">发票金额：{{i.amt_inv}}</p>
										<p v-if="i.rate_tax!==undefined">税率：{{i.rate_tax}}</p>
										<p v-if="i.amt_tax!==undefined">税额：{{i.amt_tax}}</p>
										<p v-if="i.amt_no_tax!==undefined">不含税金额：{{i.amt_no_tax}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.amt_tax_sum!==undefined">税额合计：{{xiangxi_xinxi.amt_tax_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_in_store!==undefined">入库金额：{{xiangxi_xinxi.amt_in_store}}</p>
								<p v-if="xiangxi_xinxi.amt_inv_sum!==undefined">发票金额合计：{{xiangxi_xinxi.amt_inv_sum}}</p>
								<ul>
									<li v-for='(i,index) in xiangxi_xinxi.amt_list' :key="index">
										<p>付款金额明细({{index+1}})</p>
										<p v-if="i.date_pay_est!==undefined">预计付款日期：{{i.date_pay_est}}</p>
										<p v-if="i.amt_pay_est!==undefined">预计付款金额：{{i.amt_pay_est}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.amt_pay_est_sum!==undefined">预计付款金额合计：{{xiangxi_xinxi.amt_pay_est_sum}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='vY9ubhTO89'"><!--付款单-->
								<p v-if="xiangxi_xinxi.operate_time!==undefined">付款日期：{{xiangxi_xinxi.operate_time}}</p>
								<p v-if="xiangxi_xinxi.supply_name!==undefined">供应商：{{xiangxi_xinxi.supply_name}}</p>
								<p v-if="xiangxi_xinxi.staff_name!==undefined">负责人：{{xiangxi_xinxi.staff_name}}</p>
								<p v-if="xiangxi_xinxi.dept_name!==undefined">部门：{{xiangxi_xinxi.dept_name}}</p>
								<p v-if="xiangxi_xinxi.amt_pur_inv_un_pay!==undefined">采购发票累计未结账金额：{{xiangxi_xinxi.amt_pur_inv_un_pay}}</p>
								<p v-if="xiangxi_xinxi.amt_pur_un_pay!==undefined">采购单累计未付款金额：{{xiangxi_xinxi.amt_pur_un_pay}}</p>
								<p v-if="xiangxi_xinxi.amt_in_store!==undefined">累计入库金额：{{xiangxi_xinxi.amt_in_store}}</p>
								<p v-if="xiangxi_xinxi.amt_pay!==undefined">付款金额：{{xiangxi_xinxi.amt_pay}}</p>
								<p v-if="xiangxi_xinxi.amt_pay_sum!==undefined">累计付款金额：{{xiangxi_xinxi.amt_pay_sum}}</p>
								<p v-if="xiangxi_xinxi.bank_name!==undefined">开户行名称：{{xiangxi_xinxi.bank_name}}</p>
								<p v-if="xiangxi_xinxi.bank_acct!==undefined">银行账号：{{xiangxi_xinxi.bank_acct}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='fZ0x5qedzn'"><!--入库单-->
								<p v-if="xiangxi_xinxi.date_in_store!==undefined">入库日期：{{xiangxi_xinxi.date_in_store}}</p>
								<p v-if="xiangxi_xinxi.supply_name!==undefined">供应商名称：{{xiangxi_xinxi.supply_name}}</p>
								<ul>
									<li v-for='(i,index) in xiangxi_xinxi.prod_list' :key="index">
										<p>列表({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.site_name!==undefined">仓库：{{i.site_name}}</p>
										<p v-if="i.site_no!==undefined">货位号：{{i.site_no}}</p>
										<p v-if="i.supply_name!=undefined">供应商：{{i.supply_name}}</p>
										<p v-if="i.price_quotation!==undefined">产品报价：{{i.price_quotation}}</p>
										<p v-if="i.qty_in_store!==undefined">入库数量：{{i.qty_in_store}}</p>
										<p v-if="i.amt_in_store!==undefined">入库金额：{{i.amt_in_store}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.qty_in_store_sum!==undefined">入库数量合计：{{xiangxi_xinxi.qty_in_store_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_in_store_sum!==undefined">入库金额合计：{{xiangxi_xinxi.amt_in_store_sum}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='hXstgwxhzh'"><!--出库单-->
								<p v-if="xiangxi_xinxi.staff_name!==undefined">出库员：{{xiangxi_xinxi.staff_name}}</p>
								<p v-if="xiangxi_xinxi.dept_name!==undefined">部门：{{xiangxi_xinxi.dept_name}}</p>
								<p v-if="xiangxi_xinxi.cust_name!==undefined">客户：{{xiangxi_xinxi.cust_name}}</p>
								<p v-if="xiangxi_xinxi.file_no!==undefined">归档号：{{xiangxi_xinxi.file_no}}</p>
								<p v-if="xiangxi_xinxi.date_out_store!==undefined">出库日期：{{xiangxi_xinxi.date_out_store}}</p>
								<ul>
									<li v-for='(i,index) in xiangxi_xinxi.prod_list' :key="index">
										<p>列表({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.site_name!==undefined">仓库：{{i.site_name}}</p>
										<p v-if="i.site_no!==undefined">货位号：{{i.site_no}}</p>
										<p v-if="i.supply_name!==undefined">供应商：{{i.supply_name}}</p>
										<p v-if="i.price_unit!==undefined">产品单价：{{i.price_unit}}</p>
										<p v-if="i.qty_out_store!==undefined">出库数量：{{i.qty_out_store}}</p>
										<p v-if="i.amt_out_store!==undefined">出库金额：{{i.amt_out_store}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.qty_out_store_sum!==undefined">出库数量合计：{{xiangxi_xinxi.qty_out_store_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_out_store_sum!==undefined">出库金额合计：{{xiangxi_xinxi.amt_out_store_sum}}</p>
							</div>
              <div v-if="xiangqing.flow_id=='AY3cli7BAb'"><!--会员充值单-->
                <!-- <p v-if="xiangxi_xinxi.operate_user_name!==undefined">填写人：{{xiangxi_xinxi.operate_user_name}}</p> -->
				<p v-if="xiangxi_xinxi.operate_user_name!==undefined">填写人：{{xiangxi_xinxi.operate_user_name}}</p>
								<p v-if="xiangxi_xinxi.staff_name!==undefined">业务人员：{{xiangxi_xinxi.staff_name}}</p>
								<p v-if="xiangxi_xinxi.dept_name!==undefined">部门：{{xiangxi_xinxi.dept_name}}</p>
								
                <p v-if="xiangxi_xinxi.mobile!==undefined">客户手机号：{{xiangxi_xinxi.mobile}}</p>
                <p v-if="xiangxi_xinxi.amt_bgn!==undefined">期初余额：{{xiangxi_xinxi.amt_bgn}}</p>
                <p v-if="xiangxi_xinxi.amt_topup!==undefined">充值金额：{{xiangxi_xinxi.amt_topup}}</p>
                <p v-if="xiangxi_xinxi.amt_give!==undefined">赠送金额：{{xiangxi_xinxi.amt_give}}</p>
                <p v-if="xiangxi_xinxi.amt_end!==undefined">期末余额：{{xiangxi_xinxi.amt_end}}</p>
                <p v-if="xiangxi_xinxi.date_topup!==undefined">充值日期：{{xiangxi_xinxi.date_topup}}</p>
                <template v-if="xiangxi_xinxi.pay_detail!==undefined">
                   <li v-for="(item,index) in xiangxi_xinxi.pay_detail" :key="index" style="padding-left:0.2rem;color:#888888;line-height:0.32rem;">结算方式：{{item.pay_type}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;结算金额：{{item.amt_pay}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;结算占比%：{{item.rate_pay}}</li>
                </template>
                <p v-if="xiangxi_xinxi.amt_topup_sum!==undefined">累计充值金额：{{xiangxi_xinxi.amt_topup_sum}}</p>
                <p v-if="xiangxi_xinxi.level_name!==undefined">会员等级：{{xiangxi_xinxi.level_name === 'null'? '-':xiangxi_xinxi.level_name}}</p>
                <p v-if="xiangxi_xinxi.discount!==undefined">会员折扣%：{{xiangxi_xinxi.discount === 'null'? '-':xiangxi_xinxi.discount}}</p>
							</div>
							<!-- <ul>
								<li v-for="(i,index) in xiangqing.extra" :key="index">
									<p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p>
								</li>
							</ul> -->
							<ul>
								<li v-for="(i,index) in xiangxi_xinxi.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
										<p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(it,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(it)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{it.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'&&i.liebiao!=undefined&&i.liebiao.length!=0">
										<p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(it)" v-for="(it,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{it.flow_desc}}:{{it.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(it,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in it.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'&&ite.img_url!=undefined&&ite.img_url.length!=0">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
						</div>
					</transition>
				</div>
				<div class="youce_xiangxi_xinxi" v-if="xiangxi_xinxi.length==0">
					<p class="xiangxi_tit" style="font-size:0.26rem;color:red;" v-if="xiangqing.flow_id=='7TvJHPyovM'">{{xiangqing.kehu_tishi}}</p>
					<p class="xiangxi_tit" style="font-size:0.26rem;color:red;" v-if="xiangqing.flow_id=='PLOQ9ku3Ip'">该漏斗单已被修改，请去最新的漏斗单查询</p>
				</div>
				<div class="youce_box_con">
					<p v-if="xiangqing.juese!=undefined&&xiangqing.juese.length!=0">当前角色：<span>{{xiangqing.juese}}</span></p>
					<p v-if="xiangqing.bumen!=undefined&&xiangqing.bumen.length!=0">所在部门：<span>{{xiangqing.bumen}}</span></p>
					<!-- <p>审批编号：<span>{{xiangqing.flow_no}}</span></p> -->
					<p v-if="xiangqing.time!=undefined&&xiangqing.time.length!=0">发起时间：<span>{{xiangqing.time}}</span></p>
					<p v-if="xiangqing.flow_desc!=undefined&&xiangqing.flow_desc.length!=0">内容摘要：<span>{{xiangqing.flow_desc}}</span></p>
				</div>
				<ul class="liucheng">
					<li class="liucheng_li" v-for="(i,index) in liucheng_list" :key="index">
						<div class="liucheng_li_box" v-if="!i.liebiao">
							<div class="liucheng_ren" v-if="!i.pinglun">
								<div class="liucheng_ren_box">
									<p class="liucheng_touxiang">{{i.staff_name[0]}}</p>
									<span :style="i.appr_status==0||i.appr_status==4||i.appr_status==5||i.appr_status==6||i.appr_status==8?'background:rgb(21, 188, 131)':(i.appr_status==1?'background:rgb(255, 148, 62)':(i.appr_status==2||i.appr_status==3?'background:red':'background:rgb(227, 227, 228)'))"></span>
									<div>
										<!-- <p>{{i.user_type==0?'发起申请':(i.user_type==1?'审批人':'抄送人')}}</p> -->
										<p>{{i.user_type==0?'发起申请':(i.user_type==1?'审批人':'抄送人')}}</p>
										<p>{{i.user_id==$jichuxinxi().user_id?'我':i.staff_name}}({{zhuangtai_name(i.appr_status,i.user_type,i.auto_appr_flag,i.operate_time)}})</p>
									</div>
								</div>
								<p class="liucheng_time">{{i.operate_time}}</p>
							</div>
							<p v-if="i.appr_opn&&!i.pinglun" class="liucheng_pinglun">“{{i.appr_opn}}”</p>
							<div v-if="i.pinglun" class="pinglun_zhanshi">
								<div>
									<p>
										<span>{{i.cmt_user}}</span>添加了评论
									</p>
									<span class="shijian">{{i.operate_time}}</span>
								</div>
								<p class="pinglun_neirong">"{{i.cmt_desc}}"</p>
							</div>
						</div>
						<div class="liucheng_liebiao" v-if="i.liebiao">
							<div class="liucheng_lb_box">
								<div class="liucheng_liebiao_box">
									<p class="liucheng_touxiang">{{i.user_type==0?'发':(i.user_type==1?'审':'抄')}}</p>
									<span :style="i.appr_status==0||i.appr_status==4||i.appr_status==5||i.appr_status==6||i.appr_status==8?'background:rgb(21, 188, 131)':(i.appr_status==1?'background:rgb(255, 148, 62)':(i.appr_status==2||i.appr_status==3?'background:red':'background:rgb(227, 227, 228)'))"></span>
									<div>
										<p>{{i.user_type==0?'发起申请':(i.user_type==1?'审批人':'抄送人')}}</p>
										<p v-if="!i.zidong_tongguo">{{i.list.length}}人<s v-if="i.user_type!=2">{{i.appr_sort==1?'依次':(i.appr_sort==2?"会签":'或签')}}</s></p>
										<p v-if="i.zidong_tongguo">将自动通过</p>
									</div>
								</div>
								<p class="liucheng_time">{{i.operate_time}}</p>
							</div>
							<ul>
								<li v-for="(it,idx) in i.list" :key="idx">
									<p>{{it.staff_name[0]}}</p>
									<el-tooltip class="item" effect="dark" :content="it.staff_name" placement="bottom">
										<p>{{it.staff_name}}</p>
									</el-tooltip>
								</li>
							</ul>
						</div>
					</li>
				</ul>
				<div class="youce_box_foot">
					<p @click="dianji_pinglun">评论</p>
					<!-- <p @click="dakai_bumen">转交</p>
					<p @click="dianji_tuihui">退回</p>
					<p @click="dianji_jujue">拒绝</p>
					<p @click="dianji_tongyi">同意</p> -->
				</div>
			</div>
		</div>
		<div class="pinglun" v-if="shuru_pinglun" @click="shuru_pinglun=false">
			<div class="box" @click.stop>
				<p class="tit">
					{{shuohua_txt}}
					<span @click="shuru_pinglun=false">X</span>
				</p>
				<textarea class="input" placeholder="请输入评论" v-model="pinglun_text"></textarea>
				<div class="foot">
					<span @click="dianji_fasong_pinglun">发送</span>
					<span @click="shuru_pinglun=false">取消</span>
				</div>
			</div>
		</div>
		<div class="zhanshi_renyuan" v-if="lianxiren" @click="lianxiren=false">
			<div class="box" @click.stop>
				<p class="xuanze_renyuan_tit">选择人员</p>
				<div class="tiao_bumen">
					<div class="xuanze">
						<p class="tit">选择：</p>
						<div class="xuanze_sousuo">
							<i class="el-icon-search"></i>
							<input type="text" placeholder="搜索" @keyup="keyua" v-model="text">
						</div>
						<div class="mianbaoxie">
							<div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
								<p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
								<i class="el-icon-arrow-right"></i>
							</div>
						</div>
						<div class="nei_box">
							<div class="liebiao">
								<ul>
									<li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
										<p style="margin-left:0.16rem;">{{i.dept_name}}</p>
										<img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt="">
									</li>
								</ul>
								<ul>
									<li v-for="(i,index) in qiye_renyuan_list" @click="dianji_xuanren(index)" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
										<!-- <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)"> -->
										<img class="img2" :src="renyuan==index?require('../assets/red_quan.png'):require('../assets/ccc_quan.png')" alt="">
										<p>{{i.name}}</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="foote">
					<p class="queding" @click="renyuan_sure">确定</p>
					<p class="quxiao" @click="lianxiren=false">取消</p>
				</div>
			</div>
		</div>
		<yanjing ref="eye"></yanjing>
		<tupian ref="bigphote"></tupian>
    </div>
</template>

<script>
import { query_form_replace_write_user, query_customer_detail_form, query_edit_list, generate_pdf_form, query_appr_step_list, deliver_flow_action, get_staff_list_like, query_dept_staff_list, query_ent_dept_list, appr_flow_action, query_appr_detail, insert_flow_cmt, query_flow_cmt_list, query_flow_info_list, query_flow_list_have_appr_by_me } from '../api/api'
import heade from '../approvalmodal/Approvalb'
import zongqiu from '../components/Zonganniu'
import yanjing from '../page/pagea/yanjing'
import tupian from './bigphote/bigphote'
import BiaodanXiangqing from './biaodan/biaodanxq'
export default {
  name: 'ihaveapp',
  data () {
    return {
      list: [],
      shenpi_zhuangtai: ['全部', '审批完成', '审批中', '已撤销'],
      leixing_name: '',
      guanjian_zi: '',
      shenpi_con: [],
      dianji_chuxian_youce: false,
      dangqian: '0',
      xiangqing: {
        tit: '',
        img: '',
        name: '',
        zhuangtai: '',
        bianhao: '',
        bumen: '',
        liyou: '',
        flow_no: '',
        time: '',
        flow_desc: '',
        juese: '',
        touxiang_text: '',
        flow_id: ''
      },
      shenpi_list: [],
      shuru_pinglun: false,
      pinglun_text: '',
      pinglun_list: [],
      shuohua_txt: '',
      lianxiren: false,
      mianbao_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      qiye_bumen_list: [],
      qiye_renyuan_list: [],
      text: '',
      renyuan: '0',
      xiangqing_zhankai: false,
      xiangxi_xinxi: {},

      liucheng_list: [],
      yanjing_zhanshi: false,
      dangqian_shenpi_ren: '',

      flag_replace_write: '0', // 1别人代写   0 自己写
      replace_write_user_name: '' // 别人代写的名字
    }
  },
  components: { zongqiu, heade, yanjing, BiaodanXiangqing, tupian },
  created () {
    this.jichu()
	  this.mianbao_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  mounted () {
  },
  watch: {
	  dianji_chuxian_youce: {
      handler (newValue, oldValue) {
        if (newValue == false) {
          this.xiangxi_xinxi = {}
          this.xiangqing_zhankai = false
          this.xiangqing = {}
        }
        // this.kehu_shifou_cunzai()
      },
      deep: true
    },
    shuru_pinglun () {
      if (!this.shuru_pinglun) {
        this.pinglun_text = ''
        this.pinglun_list = []
      }
    }
  },
  methods: {
		  // 基础信息
    jichu () {
      sessionStorage.removeItem('mlbb_one_shezhi')
      sessionStorage.removeItem('mlbb_four_shezhi')
      sessionStorage.removeItem('mlbb_shenpi_zhengyi')
      sessionStorage.removeItem('mlbb_biaodan_sheji')
      sessionStorage.removeItem('mlbb_liucheng_shezhi')
      sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission')
      sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId')
      sessionStorage.removeItem('mlbb_liucheng_shezhi_list')
      query_flow_list_have_appr_by_me({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          const list = []
          for (let i = 0; i < date.length; i++) {
            if (date[i].appr_status) {
              date[i].caozuo = this.zhuangtai_name(date[i].appr_status, undefined, 0)
            }
            date[i].name = date[i].start_user_name + '发起了' + date[i].flow_name
          }
          this.shenpi_con = date
          this.shenpi_list = date
          this.keyu()
          // for(let i=date.length-1;i>=0;i--){
          // 	this.shenpi_con.push(date[i])
          // 	this.shenpi_list.push(date[i])
          // }
        } else if (res.data.code == 500) {}
      })
      query_flow_info_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        if (res.data.code == 200) {
          // let date=JSON.parse(res.data.body.data)
          // console.log(date)
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          const data = []
          date.forEach(item => {
            this.$set(item, 'name', item.flow_group_name)
            item.children.forEach(it => {
              data.push(it)
            })
          })
          this.list = data
          this.list.unshift({
            flow_name: '全部',
            flow_id: 'all'
          })
          this.leixing_name = this.leixing_name != undefined && this.leixing_name.length != 0 ? this.leixing_name : 'all'
          this.keyu()
        }
      })
    },
    fapiao_wenzi_zhuanhuan (id) {
      if (id == 1) {
        return '增值税专用发票(抵扣)'
      } else if (id == 2) {
        return '增值税专用发票(不抵扣)'
      } else if (id == 3) {
        return '增值税普通发票'
      } else if (id == 4) {
        return '不开发票'
      }
    },
    // 点击选择审批类型
    dianji_xuanze () {
      const list = []
      for (let i = 0; i < this.shenpi_list.length; i++) {
        if (this.leixing_name == 'all') {
          list.push(this.shenpi_list[i])
        } else if (this.shenpi_list[i].flow_id == this.leixing_name) {
          list.push(this.shenpi_list[i])
        }
      }
      this.shenpi_con = list
    },
    dianji_xiangqing (i) {
      console.log(i)
      this.dianji_chuxian_youce = true //operate_user_name
      this.xiangqing.tit = i.name
      this.xiangqing.img = i.img
      this.yanjing_zhanshi = false
      this.xiangqing.zhuangtai = i.caozuo
      this.xiangqing.name = i.start_user_name
      this.xiangqing.flow_no = i.flow_no
      this.xiangqing.flow_name = i.flow_name
      this.xiangqing.time = i.start_time
      this.xiangqing.flow_desc = i.flow_desc
      this.xiangqing.flow_id = i.flow_id
      this.xiangqing.touxiang_text = i.start_user_name.substring(0, 1)
      this.xiangqing.kehu_tishi = i.del_flag == 1 ? '客户已删除' : '客户已修改'
      // query_edit_list({data:{
      // 	ent_id:this.$ent_id(),
      // 	flow_id:i.flow_id,
      // 	flow_no:i.flow_no,
      // 	node_sort:i.node_sort
      // }}).then(res=>{
      // 	console.log(res)
      // 	if(res.data.code==200){
      // 		s
      // 	}else if(res.data.code==500){}
      // })
      query_appr_detail({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          flow_no: i.flow_no,
          flow_id: i.flow_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.appr_info)
          const data = JSON.parse(res.data.body.appr_step)
          const datb = JSON.parse(res.data.body.staff)
          const xiangqing = JSON.parse(res.data.body.appr_form_data)
          console.log(xiangqing)
          console.log(data)
          console.log(datb)
          console.log(date)
          this.xiangqing.bumen = datb.dept_name
          this.xiangqing.juese = datb.post_name
          xiangqing.zidingyi = xiangqing.extra != undefined ? JSON.parse(xiangqing.extra) : []
          // xiangqing.zidingyi.forEach(ite=>{
          // 	if(ite.type=='cust'&&ite.value_id!=undefined&&ite.value_id.length!=0){
          // 		if(this.xiangxi_xinxi.cust_id!=undefined&&this.xiangxi_xinxi.cust_id.length!=0){

          // 		}else{
          // 			xiangqing.cust_id=ite.value_id
          // 		}
          // 	}
          // })

          if (this.xiangxi_xinxi.cust_id != undefined && this.xiangxi_xinxi.cust_id.length != 0) {

          } else {
            for (let ia = 0; ia < xiangqing.zidingyi.length; ia++) {
              if (xiangqing.zidingyi[ia].type == 'cust') {
                xiangqing.cust_id = xiangqing.zidingyi[ia].value_id
                console.log(xiangqing.zidingyi[ia].value_id)

                break
              }
            }
          }
          if (xiangqing.cust_id != undefined && xiangqing.cust_id.length != 0) {
            this.yanjing_zhanshi = true
          } else {
            this.yanjing_zhanshi = false
          }
          const quanxian = i.edit_list != undefined ? JSON.parse(i.edit_list) : []
          console.log(xiangqing)
          if (quanxian.length != 0) {

            quanxian.forEach(item => {
              xiangqing.zidingyi.forEach(ite => {
                if (ite.idx == item.idx) {
                  this.$set(ite, 'zhi', item.type)
                }
                if (ite.type == 'tablefield') {
                  ite.mingxi_list.forEach(it => {
                    it.mingxi_list.forEach(i => {
                      if (i.type == item.type && ((ite.name + '.' + i.name) == item.name)) {
                        this.$set(i, 'zhi', item.type)
                      }
                    })
                  })
                }
                if (ite.type == 'dddatefield') {
                  ite.value_ids = ite.value.length != 0 ? new Date(ite.value) : ''
                } else if (ite.type == 'dddaterangefield') {
                  if (ite.value.length != 0) {
                    const idx = ite.value.lastIndexOf('至')
                    const one = new Date(ite.value.substring(ite.value, idx))
                    const two = new Date(ite.value.substring(idx + 1))
                    const sj_qj = []
                    sj_qj[0] = one
                    sj_qj[1] = two
                    ite.value_ids = sj_qj
                  } else {
                    ite.value_ids = ''
                  }

                  // setTimeout(() => {
                  //     this.$set(data[i],'value',sj_qj)
                  //     console.log(sj_qj)
                  // }, 0)
                }
              })
              xiangqing.zidingyi.forEach(ite => {
                console.log(ite)

                if (ite.zhi == undefined) {
                  this.$set(ite, 'zhi', '2')
                }
                if (ite.type == 'tablefield') {
                  ite.mingxi_list.forEach(it => {
                    it.mingxi_list.forEach(i => {
                      if (i.zhi == undefined) {
                        this.$set(i, 'zhi', '2')
                      }
                      if (i.type == 'dddatefield') {
                        i.value_ids = i.value.length != 0 ? new Date(i.value) : ''
                      } else if (i.type == 'dddaterangefield') {
                        if (i.value.length != 0) {
                          const idx = i.value.lastIndexOf('至')
                          const one = new Date(i.value.substring(i.value, idx))
                          const two = new Date(i.value.substring(idx + 1))
                          const sj_qj = []
                          sj_qj[0] = one
                          sj_qj[1] = two
                          i.value_ids = sj_qj
                        } else {
                          i.value_ids = ''
                        }
                        // setTimeout(() => {
                        //     this.$set(data[i],'value',sj_qj)
                        //     console.log(sj_qj)
                        // }, 0)
                      }
                    })
                  })
                }
              })
            })
          } else {
            xiangqing.zidingyi.forEach(ite => {
              if (ite.zhi == undefined) {
                this.$set(ite, 'zhi', '2')
              }
              if (ite.type == 'tablefield') {
                ite.mingxi_list.forEach(it => {
                  it.mingxi_list.forEach(i => {
                    if (i.zhi == undefined) {
                      this.$set(i, 'zhi', '2')
                    }
                    if (i.type == 'dddatefield') {
                      i.value_ids = i.value.length != 0 ? new Date(i.value) : ''
                    } else if (i.type == 'dddaterangefield') {
                      if (i.value.length != 0) {
                        const idx = i.value.lastIndexOf('至')
                        const one = new Date(i.value.substring(i.value, idx))
                        const two = new Date(i.value.substring(idx + 1))
                        const sj_qj = []
                        sj_qj[0] = one
                        sj_qj[1] = two
                        i.value_ids = sj_qj
                      } else {
                        i.value_ids = ''
                      }
                      // setTimeout(() => {
                      //     this.$set(data[i],'value',sj_qj)
                      //     console.log(sj_qj)
                      // }, 0)
                    }
                  })
                })
              }
              if (ite.type == 'dddatefield') {
                ite.value_ids = ite.value.length != 0 ? new Date(ite.value) : ''
              } else if (ite.type == 'dddaterangefield') {
                if (ite.value.length != 0) {
                  const idx = ite.value.lastIndexOf('至')
                  const one = new Date(ite.value.substring(ite.value, idx))
                  const two = new Date(ite.value.substring(idx + 1))
                  const sj_qj = []
                  sj_qj[0] = one
                  sj_qj[1] = two
                  ite.value_ids = sj_qj
                } else {
                  ite.value_ids = ''
                }

                // setTimeout(() => {
                //     this.$set(data[i],'value',sj_qj)
                //     console.log(sj_qj)
                // }, 0)
              }
            })
          }
          this.xiangxi_xinxi = xiangqing
        } else if (res.data.code == 500) {} else if (res.data.code == 700) {
          this.yanjing_zhanshi = false
          this.xiangxi_xinxi = ''
        }
      })
      this.chaxun_pinglun()
    },
    // 点击切换展示内容
    dianji_qiehuan (index) {
      this.dangqian = index
      this.leixing_name = 'all'
      this.shenpi_con = []
      if (index == 0) {
        this.shenpi_con = this.shenpi_list
      } else if (index == 1) {
        // this.shenpi_con
        for (let i = 0; i < this.shenpi_list.length; i++) {
          if (this.shenpi_list[i].appr_status == 5 || this.shenpi_list[i].appr_status == 4) {
            this.shenpi_con.push(this.shenpi_list[i])
          }
        }
      } else if (index == 2) {
        for (let i = 0; i < this.shenpi_list.length; i++) {
          if (this.shenpi_list[i].appr_status == 0 || this.shenpi_list[i].appr_status == 1) {
            this.shenpi_con.push(this.shenpi_list[i])
          }
        }
      } else if (index == 3) {
        for (let i = 0; i < this.shenpi_list.length; i++) {
          if (this.shenpi_list[i].appr_status == 7) {
            this.shenpi_con.push(this.shenpi_list[i])
          }
        }
      }
    },
    // 搜索
    keyu () {
      const list = []
      let list2 = []; const list3 = []
      if (this.dangqian == 0) {
        list2 = this.shenpi_list
      } else if (this.dangqian == 1) {
        for (let i = 0; i < this.shenpi_list.length; i++) {
          if (this.shenpi_list[i].appr_status == 5) {
            list2.push(this.shenpi_list[i])
          }
        }
      } else if (this.dangqian == 2) {
        for (let i = 0; i < this.shenpi_list.length; i++) {
          if (this.shenpi_list[i].appr_status == 0 || this.shenpi_list[i].appr_status == 1) {
            list2.push(this.shenpi_list[i])
          }
        }
      } else if (this.dangqian == 3) {
        for (let i = 0; i < this.shenpi_list.length; i++) {
          if (this.shenpi_list[i].appr_status == 7) {
            list2.push(this.shenpi_list[i])
          }
        }
      }
      for (let i = 0; i < list2.length; i++) {
        if (list2[i].name.indexOf(this.guanjian_zi) != -1 || list2[i].flow_desc.indexOf(this.guanjian_zi) != -1 || list2[i].start_time.indexOf(this.guanjian_zi) != -1 || (list2[i].cust_name != undefined && list2[i].cust_name.indexOf(this.guanjian_zi) != -1)) {
          list.push(list2[i])
        }
      }
      setTimeout(() => {
        this.zhanshi_shenpi_liebiao(list)
      }, 0)
    },
    zhanshi_shenpi_liebiao (list) {
      const list3 = []
      list.forEach(item => {
        if (item.flow_id == this.leixing_name) {
          list3.push(item)
        } else if (this.leixing_name == 'all') {
          list3.push(item)
        }
      })
      this.shenpi_con = list3
    },
    // 点击发送评论
    dianji_fasong_pinglun () {
      if (this.shuohua_txt == '评论') {
        insert_flow_cmt({
          data: {
            ent_id: this.$ent_id(),
            flow_no: this.xiangqing.flow_no,
            cmt_user: this.$jichuxinxi().name,
            cmt_desc: this.pinglun_text
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.shuru_pinglun = false
            this.pinglun_text = ''
            this.chaxun_pinglun()
          } else if (res.data.code == 500) {}
        })
      } else if (this.shuohua_txt == '通过理由（非必填）') {
        let pinglun1 = null
        if (this.pinglun_text.length != 0) {
          pinglun1 = this.pinglun_text
        }
        appr_flow_action({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            appr_status: '4',
            appr_opn: pinglun1,
            flow_id: this.xiangqing.flow_id,
            flow_no: this.xiangqing.flow_no
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.shuru_pinglun = false
            this.pinglun_text = ''
            // this.chaxun_pinglun()
          } else if (res.data.code == 500) {}
        })
      } else if (this.shuohua_txt == '退回理由（非必填）') {
        let pinglun1 = null
        if (this.pinglun_text.length != 0) {
          pinglun1 = this.pinglun_text
        }
        appr_flow_action({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            appr_status: '3',
            appr_opn: pinglun1,
            flow_id: this.xiangqing.flow_id,
            flow_no: this.xiangqing.flow_no
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.shuru_pinglun = false
            this.pinglun_text = ''
            // this.chaxun_pinglun()
          } else if (res.data.code == 500) {}
        })
      } else if (this.shuohua_txt == '拒绝理由（非必填）') {
        let pinglun1 = null
        if (this.pinglun_text.length != 0) {
          pinglun1 = this.pinglun_text
        }
        appr_flow_action({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            appr_status: '2',
            appr_opn: pinglun1,
            flow_id: this.xiangqing.flow_id,
            flow_no: this.xiangqing.flow_no
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.shuru_pinglun = false
            this.pinglun_text = ''
            // this.chaxun_pinglun()
          } else if (res.data.code == 500) {}
        })
      }
    },
    zhuangtai_name (i, a, b, c) {
      if (i == 0) {
        if (this.flag_replace_write == 1) {
          return this.replace_write_user_name + '代提交'
        } else {
          return '发起审批'
        }
      } else if (i == 1 && a != 2) {
        return '审批中'
      } else if (i == 2) {
        return '已拒绝'
      } else if (i == 3) {
        return '已退回'
      } else if (i == 4 && (b == 0 || b == undefined)) {
        return '审批通过'
      } else if (i == 5) {
        return '已归档'
      } else if (i == 6) {
        return '已转交'
      } else if (i == 7) {
        return '已撤销'
      } else if (i == 8) {
        return '已抄送'
      }
      if (b == 1 && c == undefined) {
        return '审批将自动通过'
      } else if (b == 1 && c != undefined) {
        return '已自动通过'
      }
    },
    chaxun_pinglun () {
      if (this.xiangxi_xinxi.length != 0) {
        query_form_replace_write_user({
          data: {
            ent_id: this.$ent_id(),
            flow_no: this.xiangqing.flow_no
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            if (date) {
              this.flag_replace_write = date.flag_replace_write
              this.replace_write_user_name = date.replace_write_user_name
            } else {
              this.flag_replace_write = '0'
              this.replace_write_user_name = ''
            }
          } else if (res.data.code == 500) {}
        })
      }
      query_appr_step_list({
        data: {
          ent_id: this.$ent_id(),
          flow_id: this.xiangqing.flow_id,
          flow_no: this.xiangqing.flow_no
        }
      }).then(lc => {
        console.log(lc)
        if (lc.data.code == 200) {
          const lc_list = JSON.parse(lc.data.body.data)
          console.log(lc_list)
          const list = []
          for (let i = 0; i < lc_list.length; i++) {
            if (lc_list[i].history_flag != undefined) {
              this.$set(lc_list[i], 'pinglun', false)
              this.$set(lc_list[i], 'liebiao', false)
              list.push(lc_list[i])
            } else {
              if (lc_list[i].user_type == 0) {
                this.$set(lc_list[i].list[0], 'pinglun', false)
                this.$set(lc_list[i].list[0], 'liebiao', false)
                list.push(lc_list[i].list[0])
              } else if (lc_list[i].user_type == 1) {
                if (lc_list[i].appr_sort == 1) {
                  for (let b = 0; b < lc_list[i].list.length; b++) {
                    this.$set(lc_list[i].list[b], 'pinglun', false)
                    this.$set(lc_list[i].list[b], 'liebiao', false)
                    list.push(lc_list[i].list[b])
                  }
                } else if (lc_list[i].appr_sort == 2) {
                  for (let b = 0; b < lc_list[i].list.length; b++) {
                    if (lc_list[i].list[b].appr_status == 4 || lc_list[i].list[b].appr_status == 3 || lc_list[i].list[b].appr_status == 6) {
                      this.$set(lc_list[i].list[b], 'pinglun', false)
                      this.$set(lc_list[i].list[b], 'liebiao', false)
                      list.push(lc_list[i].list[b])
                      lc_list[i].list.splice(b, 1)
                      b = b - 1
                    }
                  }
                  if (lc_list[i].list.length != 0) {
                    this.$set(lc_list[i], 'pinglun', false)
                    this.$set(lc_list[i], 'liebiao', true)
                    list.push(lc_list[i])
                  }
                } else if (lc_list[i].appr_sort == 3) {
                  for (let b = 0; b < lc_list[i].list.length; b++) {
                    if (lc_list[i].list[b].appr_status == 4 || lc_list[i].list[b].appr_status == 3 || lc_list[i].list[b].appr_status == 6) {
                      this.$set(lc_list[i].list[b], 'pinglun', false)
                      this.$set(lc_list[i].list[b], 'liebiao', false)
                      list.push(lc_list[i].list[b])
                      lc_list[i].list = []
                    }
                  }
                  if (lc_list[i].list.length != 0) {
                    this.$set(lc_list[i], 'pinglun', false)
                    this.$set(lc_list[i], 'liebiao', true)
                    list.push(lc_list[i])
                  }
                }
              } else if (lc_list[i].user_type == 2) {
                this.$set(lc_list[i], 'pinglun', false)
                this.$set(lc_list[i], 'liebiao', true)
                this.$set(lc_list[i], 'appr_status', lc_list[i].list[0].appr_status)
                this.$set(lc_list[i], 'operate_time', lc_list[i].list[0].operate_time)
                list.push(lc_list[i])
              }
            }
          }
          query_flow_cmt_list({
            data: {
              ent_id: this.$ent_id(),
              flow_no: this.xiangqing.flow_no
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              date.map(item => {
                this.$set(item, 'pinglun', true)
                this.$set(item, 'liebiao', false)
                this.$set(item, 'operate_time', item.cmt_time)
                list.push(item)
              })
              let you_sj = []
              for (let a = 0; a < list.length; a++) {
                if (list[a].operate_time != undefined) {
                  you_sj.push(list[a])
                }
              }
              you_sj = this.$func.ForwardRankingDate(you_sj, 'operate_time')
              let shenpi_ren = 'aa'
              for (let a = 0; a < list.length; a++) {
                if (list[a].operate_time == undefined) {
                  you_sj.push(list[a])
                  console.log(list[a])
                  if (list[a].user_type == 1 && shenpi_ren == 'aa') {
                    // console.log(this.xiangxi_xinxi.zidingyi)
                    console.log(list[a])
                    this.dangqian_shenpi_ren = list[a]
                    shenpi_ren = 'bb'
                  }
                }
              }
              // for(let a=0;a<list2.length;a++){
              // 	you_sj.push(list2[a])
              // }
              you_sj.forEach(item => {
                if (item.list != undefined) {
                  const zidong_tongguo = []
                  item.list.forEach(ite => {
                    if (ite.auto_appr_flag == 1) {
                      zidong_tongguo.push('1111')
                    }
                  })
                  if (zidong_tongguo.length != 0 && zidong_tongguo.length == item.list.length) {
                    this.$set(item, 'zidong_tongguo', true)
                  }
                }
              })
              this.liucheng_list = you_sj
            } else if (res.data.code == 500) {}
          })
        } else if (lc.data.code == 500) {}
      })
    },
    gai_shei_shenpi (i) {
      console.log(i)
      if (i.list != undefined) {
        let aa = 'aa'
        i.list.forEach(item => {
          if (item.user_id == this.$jichuxinxi().user_id) {
            aa = 'bb'
          }
        })
        if (aa == 'aa') {
          return '等待审批中'
        } else {
          return '等待我审批'
        }
      } else {
        if (i.user_id == this.$jichuxinxi().user_id) {
          return '等待我审批'
        } else if (i.staff_name != undefined) {
          return '等待' + i.staff_name + '审批'
        } else {
          return this.xiangqing.zhuangtai
        }
      }
    },
    // 点击评论
    dianji_pinglun () {
      this.shuohua_txt = '评论'
      this.shuru_pinglun = true
    },
    // 点击通过
    dianji_tongyi () {
      this.shuohua_txt = '通过理由（非必填）'
      this.shuru_pinglun = true
    },
    // 点击拒绝
    dianji_jujue () {
      this.shuohua_txt = '拒绝理由（非必填）'
      this.shuru_pinglun = true
    },
    // 点击退回
    dianji_tuihui () {
      this.shuohua_txt = '退回理由（非必填）'
      this.shuru_pinglun = true
    },
    // 部门列表   自己的
    bumen_liebiao () {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1',
          parent_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_bumen_list = date
        } else if (res.data.code == 10169) {
        }
      })
      query_dept_staff_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          parent_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id,
          active: '1'
        }
      }).then(ras => {
        if (ras.data.code == 10149) {
          const listdata = JSON.parse(ras.data.body.data)
          console.log(99999, listdata)
          this.qiye_renyuan_list = listdata
        } else if (ras.data.code == 10150) {
        }
      })
    },
    dakai_bumen () {
      this.mianbao_list.splice(1, this.mianbao_list.length)
      this.bumen_liebiao()
      this.lianxiren = true
    },
    // 单选人
    dianji_xuanren (index) {
      this.renyuan = index
    },
    // 点击部门
    dianji_bumen (i) {
      console.log(44444, i)
      if (i.child_flag == 1) {
        query_ent_dept_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            parent_id: i.dept_id,
            active: '1'
          }
        }).then(res => {
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.qiye_bumen_list = date
            this.mianbao_list.push({ name: i.dept_name, dept_id: i.dept_id })
            query_dept_staff_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                dept_id: i.dept_id,
                active: '1'
              }
            }).then(ras => {
              if (ras.data.code == 10149) {
                const listdata = JSON.parse(ras.data.body.data)
                console.log(99999, listdata)
                this.qiye_renyuan_list = listdata
              } else if (ras.data.code == 10150) {
              }
            })
          } else if (res.data.code == 10169) {
          }
        })
      } else {
        this.$message({
          message: '该部门没有子部门了',
          type: 'warning'
        })
      }
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian (i, index) {
      console.log(i.dept_id)
      if (this.mianbao_list.length - 1 != index) {
        query_ent_dept_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            parent_id: i.dept_id,
            active: '1'
          }
        }).then(res => {
          if (res.data.code == 10168) {
            var date = JSON.parse(res.data.body.data)
            // console.log(date)
            this.qiye_bumen_list = date
            this.mianbao_list.splice(index + 1, this.mianbao_list.length)
            query_dept_staff_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                parent_id: i.dept_id,
                active: '1'
              }
            }).then(ras => {
              console.log(ras)
              if (ras.data.code == 10149) {
                const listdata = JSON.parse(ras.data.body.data)
                console.log(99999, listdata)
                this.qiye_renyuan_list = listdata
              } else if (ras.data.code == 10150) {
              }
            })
          } else if (res.data.code == 10169) {
          }
        })
      }
    },
    // 发起人里成员点击确定
    renyuan_sure () {
      deliver_flow_action({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          deliver_user_id: this.qiye_renyuan_list[this.renyuan].staff_id,
          flow_id: this.xiangqing.flow_id,
          flow_no: this.xiangqing.flow_no
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          this.$message({
            message: '请选择要删除的人员',
            type: 'success'
          })
          this.renyuan = 0
          this.lianxiren = false
          this.dianji_chuxian_youce = false
          this.jichu()
        } else if (res.data.code == 500) {}
      })
    },
    keyua () {
      if (this.text.length == 0) {
        this.text = null
      }
      get_staff_list_like({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          staff_name: this.text,
          active: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10027) {
          console.log(JSON.parse(res.data.body.data))
          const date = JSON.parse(res.data.body.data)
          this.qiye_renyuan_list = date
        } else if (res.data.code == 10028) {
        }
      })
    },
    // 点击眼睛
    dakai_danju (i) {
      this.$refs.eye.dianji_dakai(i)
    },
    dianji_xiazai_fujian (i) {
      this.$func.fujian_xiazai(i)
    },
    jieduan_hanzi (i) {
      if (i == '1') {
        return '初步接触'
      } else if (i == '2') {
        return '需求确认'
      } else if (i == '3') {
        return '报价及方案展示'
      } else if (i == '4') {
        return '商务谈判'
      } else if (i == '5') {
        return '赢单'
      } else if (i == '6') {
        return '输单'
      }
    },
    // 点击表单详情
    biaodan_xiangqing (i) {
      this.$refs.biaodan_xiangqing_zhanshi[0].jichu(i)
    },
    dianji_xiazai_danju () {
      // (xiangxi_xinxi)
      console.log(this.xiangxi_xinxi)
      generate_pdf_form({
        data: {
          ent_id: this.$ent_id(),
          flow_id: this.xiangqing.flow_id,
          flow_name: this.xiangqing.flow_name,
          flow_no: this.xiangxi_xinxi.flow_no,
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const url = JSON.parse(res.data.body.data)
          console.log(url)
          this.$func.fujian_xiazai3(url, this.$jichuxinxi().ent_info.ent_name + this.xiangqing.flow_name)
        } else if (res.data.code == 500) {}
      })
    },
    tupian_dianji (img) {
      this.$refs.bigphote.jichu(img)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ihaveapp{
    min-height: 100vh;
	background: #fff;
	.xuanfu{
		position: absolute;
		top:4.3rem;
  		left:1.5rem;
        z-index: 100;
    }
    .con{
        padding: 0.37rem 0.3rem;
        .con_tit{
            display: flex;
            align-items: center;
            input{
                width:3.9rem;
                height:0.36rem;
                padding: 0 0.3rem;
                border-radius: 0.06rem;
                border:0.01rem solid #CCCCCC;
            }
            p{
                width:1.01rem;
                height:0.36rem;
                border-radius: 0.22rem;
                background: #9a86db;
                font-size: 0.14rem;
                color:#fff;
                line-height: 0.36rem;
                text-align: center;
                margin-left:0.19rem;
                cursor: pointer;
            }
        }
        .con_xuanze_shenpi{
            display: flex;
            align-items: center;
            font-size: 0.14rem;
            color:#4C4A4D;
            margin-top:0.21rem;
            ul{
                display: flex;
                align-items: center;
                margin-left: 0.2rem;
                li{
                    padding: 0.07rem 0.22rem;
                    border:0.01rem solid #CCCCCC;
                    cursor: pointer;
                    &:nth-child(1){
                        border-radius: 0.06rem 0 0 0.06rem;
                    }
                    &:nth-child(4){
                        border-radius:0 0.06rem 0.06rem 0;
                    }
                }
            }
        }
		.ul1{
			margin-top: 0.42rem;
			.li{
				height:0.51rem;
				.li_tit{
					display: flex;
					background: #F4F7FD;
					height:0.51rem;
					align-items: center;
					justify-content: space-between;
					li{
						font-size: 0.14rem;
						color:#4C4A4D;
						width: 25%;
						padding-left: 0.6rem;
						text-align: center;
					}
				}
			}
		}
		.ul{
			height:4.8rem;
			overflow-y:auto;
			&::-webkit-scrollbar {
				width: 20px;
				height: 8px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 12px;
				border: 6px solid rgba(0, 0, 0, 0);
				box-shadow: 8px 0 0 #A5ADB7 inset;
			}

			&::-webkit-scrollbar-thumb:hover {
				box-shadow: 8px 0 0 #4A4A4A inset;
			}
			.li2{
				min-height:0.81rem;
				display: flex;
				align-items: center;
				border-bottom: 0.01rem solid #EEEEEE;
				cursor: pointer;
				&:hover{
					background:rgb(247, 247, 247);
				}
				.li_tit{
					display: flex;
					align-items: center;
					width:100%;
					li{
						// display: flex;
						// align-items: center;
						width: 25%;
						text-align: center;
						padding-left: 0.6rem;
						font-size: 0.14rem;
						color:#4C4A4D;
						img{
							width:0.3rem;
							height:0.3rem;
							border-radius: 50%;
							margin-right: 0.25rem;
						}
						position: relative;
						span{
							position: absolute;
							padding: 0.05rem 0.2rem;
							border:0.04rem solid red;
							color:red;
							border-radius: 0.04rem;
							font-weight: 600;
							top:-0.07rem;
							left:-8%;
							transform:rotate(-20deg);
							-webkit-transform:rotate(-20deg);  /*兼容-webkit-引擎浏览器*/
							-moz-transform:rotate(-20deg);
							opacity: .6;
							// margin-top: ;
						}
					}
				}
			}
		}
    }
    .youce_xianshi{
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: auto;
		margin: 0;
		z-index:100;
		.youce_box{
			position: absolute;
			right:0;
			top:0;
			width:5.8rem;
			padding: 0.32rem 0.2rem 0 0.2rem;
			box-shadow:-0.06rem 0 0.04rem 0 rgba(0,0,0,0.1);
			background:#fff;
			min-height: 96vh;
			.youce_box_tit{
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 0.18rem;
				font-weight: bold;
				color:#1A2533;
				span{
					font-size: 0.18rem;
					color:#999999;
					font-weight: 400;
					cursor: pointer;
				}
			}
			.youce_box_touxiang{
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top:0.49rem;
				padding-bottom: 0.25rem;
				border-bottom:0.01rem solid #EEEEEE;
				.youce_box_touxiang_left{
					display: flex;
					align-items: center;
					img{
						height:0.8rem;
						width:0.8rem;
						border-radius: 50%;
					}
					.toux{
						height:0.8rem;
						width:0.8rem;
						border-radius: 50%;
						background: #1890ff;
						color:#fff;
						font-size: 0.4rem;
						text-align: center;
						line-height: 0.8rem;
						font-family: NSimSun;
					}
					.youce_box_touxiang_left_text{
						margin-left: 0.27rem;
						p{
							&:nth-child(1){
								font-size: 0.2rem;
								color:#1A2533;
							}
							&:nth-child(2){
								font-size: 0.14rem;
								color:#1A2533;
								margin-top: 0.09rem;
							}
						}
					}
				}
				.youce_box_touxiang_right{
					img{
						width:0.25rem;
						cursor: pointer;
						margin-left: 0.2rem;
						margin-right: 0.1rem;
					}
					i{
						color:#9a86db;
						margin:0 0.05rem;
						font-size: 0.25rem;
						cursor: pointer;
					}
				}
			}
			.youce_xiangxi_xinxi{
				.xiangxi_tit{
					height:0.6rem;
					padding: 0 0.32rem;
					background: #f4f7fd;
					line-height: 0.6rem;
					font-size: 0.14rem;
					color:#1A2533;
					cursor: pointer;
				}
			}
			.youce_xiangxi_xinxi{
				.xiangxi_tit{
					height:0.39rem;
					padding: 0 0.32rem;
					background: #f4f7fd;
					line-height: 0.6rem;
					font-size: 0.14rem;
					color:#1A2533;
					cursor: pointer;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				.transition-box{
					padding: 0.2rem 0;
					background: #f4f7fd;
					p{
						font-size: 0.12rem;
						color:#888888;
						padding: 0.05rem 0.2rem;
						span{
							font-size: 0.12rem;
							color:#4C4A4D;
						}
					}
					ul{
						li{
							border:0.01rem solid #ccc;
							border-left: none;
							border-right: none;
						}
					}
					img{
						width:100%;
					}
					.keyi_dianji{
						cursor: pointer;
						.fujian_xiazai_kuang,.guanlian_kuang{
							padding: 0.1rem 0.35rem;
							background: #c8c2c2;
							color:#1A2533;
							i{
								font-size: 0.2rem;
							}
						}
					}
					.tupian_kuang{
						width:30%;
						cursor: pointer;
					}
				}
			}
			.youce_box_con{
				p{
					font-size: 0.12rem;
					color:#888888;
					margin: 0.05rem 0;
					span{
						font-size: 0.12rem;
						color:#4C4A4D;
					}
				}
			}
			.liucheng{
				border-top:0.01rem solid #ccc;
				padding: 0 0.1rem;
				margin-bottom: 0.5rem;
				.liucheng_li{
					margin: 0.1rem 0;
					.liucheng_li_box{
						.liucheng_ren{
							min-height: 0.6rem;
							display: flex;
							justify-content: space-between;
							.liucheng_ren_box{
								display: flex;
								align-items: center;
								position: relative;
								.liucheng_touxiang{
									height:0.5rem;
									width:0.5rem;
									border-radius: 50%;
									background: #1890ff;
									color:#fff;
									font-size: 0.2rem;
									text-align: center;
									line-height: 0.5rem;
									font-family: NSimSun;
									margin-right:0.1rem;
								}
								span{
									position: absolute;
									display: block;
									bottom:0.05rem;
									left: 0.31rem;
									height:0.2rem;
									width:0.2rem;
									border-radius: 50%;
								}
								div{
									p{
										line-height: 0.22rem;
										&:nth-child(1){
											color:#191f25;
											font-size: 0.14rem;
										}
										&:nth-child(2){
											color:#7d8184;
											font-size: 0.12rem;
										}
									}
								}
							}
							.liucheng_time{
								font-size: 0.12rem;
								color:#a8abc1;
								margin-top: 0.1rem;
							}
						}
						.liucheng_pinglun{
							font-size: 0.12rem;
							color:#ccc;
							padding: 0 0.5rem;
						}
					}
					.liucheng_liebiao{
						min-height: 0.6rem;
						.liucheng_lb_box{
							display: flex;
							justify-content: space-between;
							.liucheng_liebiao_box{
								display: flex;
								align-items: center;
								position: relative;
								.liucheng_touxiang{
									height:0.5rem;
									width:0.5rem;
									border-radius: 50%;
									background: #1890ff;
									color:#fff;
									font-size: 0.2rem;
									text-align: center;
									line-height: 0.5rem;
									font-family: NSimSun;
									margin-right:0.1rem;
								}
								span{
									position: absolute;
									display: block;
									top:0.31rem;
									left: 0.31rem;
									height:0.2rem;
									width:0.2rem;
									border-radius: 50%;
								}
								div{
									p{
										line-height: 0.22rem;
										&:nth-child(1){
											color:#191f25;
											font-size: 0.14rem;
										}
										&:nth-child(2){
											color:#7d8184;
											font-size: 0.12rem;
										}
									}
								}
							}
							.liucheng_time{
								font-size: 0.12rem;
								color:#a8abc1;
								margin-top: 0.1rem;
							}
						}
						ul{
							display: flex;
							flex-wrap: wrap;
							margin-top: 0.1rem;
							padding: 0 0.6rem;
							li{
								width: 0.4rem;
								margin: 0 0.1rem;
								p{
									&:nth-child(1){
										height:0.4rem;
										width: 0.4rem;
										margin: 0 auto;
										border-radius: 50%;
										background: #1890ff;
										color:#fff;
										font-size: 0.2rem;
										text-align: center;
										line-height: 0.4rem;
										font-family: NSimSun;
									}
									&:nth-child(2){
										width:0.4rem;
										margin: 0 auto;
										overflow: hidden;/*超出部分隐藏*/
										white-space: nowrap;/*不换行*/
										text-overflow:ellipsis;/*超出部分文字以...显示*/
										font-size: 0.12rem;
										color:#7d8184;
									}
								}
							}
						}
					}
					.pinglun_zhanshi{
						margin: 0.3rem 0;
						div{
							display: flex;
							align-items: center;
							justify-content: space-between;
							p{
								font-size: 0.16rem;
								color: #191f25;
								span{
									color:#1890ff;
								}
							}
							.shijian{
								color:#a8abad;
							}
						}
						.pinglun_neirong{
							font-size: 0.14rem;
							color: #7d8184;
						}
					}
				}
			}
			// .pinglun_yangzi{
			// 	padding-bottom: 0.47rem;
			// 	li{
			// 		margin: 0.3rem 0;
			// 		padding: 0 0.1rem;
			// 		div{
			// 			display: flex;
			// 			align-items: center;
			// 			justify-content: space-between;
			// 			p{
			// 				font-size: 0.16rem;
			// 				color: #191f25;
			// 				span{
			// 					color:#1890ff;
			// 				}
			// 			}
			// 			.shijian{
			// 				color:#a8abad;
			// 			}
			// 		}
			// 		.pinglun_neirong{
			// 			font-size: 0.14rem;
			// 			color: #7d8184;
			// 		}
			// 	}
			// }
			.youce_box_foot{
				display: flex;
				position: fixed;
				bottom: 0;
				right:0;
				width:6.2rem;
				height:0.47rem;
				align-items: center;
				border-top:0.01rem solid #EEEEEE;
				justify-content: center;
				background: #fff;
				p{
					font-size: 0.12rem;
					color:#1A2533;
					margin: 0 0.3rem;
					cursor: pointer;
					&:nth-child(4){
						width:1.2rem;
						height:0.3rem;
						border-radius:0.02rem 0 0 0.02rem;
						border:0.01rem solid rgba(136,136,136,1);
						font-size: 0.12rem;
						text-align: center;
						line-height: 0.3rem;
						color:#888888;
						margin: 0;
						margin-left: 0.3rem;
					}
					&:nth-child(5){
						width:1.2rem;
						height:0.3rem;
						background:rgba(249,108,108,1);
						border-radius:0 0.02rem 0.02rem 0;
						text-align: center;
						margin: 0;
						line-height: 0.3rem;
						border:0.01rem solid #9a86db;
						color:#fff;
					}
				}
			}
		}
	}
	.pinglun{
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: auto;
		margin: 0;
		z-index:101;
		background: rgba(0,0,0,0.5);
		.box{
			height:4.43rem;
			width:5.9rem;
			background:#fff;
			position: relative;
			top:2.42rem;
			left:50%;
			margin-left: -2.95rem;
			.tit{
				display: flex;
				padding-top:0.1rem;
				justify-content: space-between;
				margin: 0 0.49rem;
				font-size: 0.14rem;
				color: #1A2533;
				span{
					cursor: pointer;
				}
			}
			.input{
				width:4.84rem;
				height:2.63rem;
				display: block;
				margin: 0 auto;
				padding:0.1rem 0.3rem;
				margin-top:0.3rem;
			}
			.foot{
				position: absolute;
				bottom: 0;
				left: 0;
				right:0;
				height:0.63rem;
				border-top:0.01rem solid #CCCCCC;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				span{
					padding: 0.06rem 0.47rem;
					font-size: 0.12rem;
					cursor: pointer;
					&:nth-child(1){
						color:#fff;
						border:0.01rem solid #9a86db;
						background: #9a86db;
						border-radius: 0.05rem 0 0 0.05rem;
					}
					&:nth-child(2){
						color:#888888;
						border:0.01rem solid #888888;
						background: #fff;
						margin-right: 0.2rem;
						border-radius: 0 0.05rem 0.05rem 0;
					}
				}
			}
		}
	}
	.zhanshi_renyuan{
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: auto;
		margin: 0;
		z-index:11;
		background: rgba(0,0,0,0.5);
		.box{
			min-height:5.55rem;
			width:8.99rem;
			background:#fff;
			position: relative;
			top:2.42rem;
			left:50%;
			margin-left: -4.5rem;
			.xuanze_renyuan_tit{
				color: #1A2533;
				font-size: 0.22rem;
				text-align: center;
				height:0.51rem;
				line-height: 0.51rem;
			}
			.tiao_bumen{
				padding: 0.25rem 0.25rem 0 0.3rem;
				display: flex;
				align-items: center;
				justify-content:center;
				.xuanze{
					height:3.54rem;
					width:7rem;
					background: #E7E8EB;
					margin: 0 0.11rem;
					margin-top: 0.2rem;
					position: relative;
					.tit{
						position: absolute;
						left:0;
						top:-0.3rem;
						font-size: 0.14rem;
						color:#1A2533;
					}
					.xuanze_sousuo{
						position: relative;
						i{
							position: absolute;
							top:0.09rem;;
							left: 0.32rem;
						}
						input{
							height:0.3rem;
							width:6rem;
							border:none;
							background:#fff;
							padding: 0 0.33rem;
							margin: 0 auto;
							display: block;
							margin-top: 0.15rem;
							border-radius:0.02rem;
						}
					}
					.mianbaoxie{
						display: flex;
						align-items: center;
						justify-content: left;
						margin-bottom: 0.1rem;
						margin-left: 0.23rem;
						margin-top:0.12rem;
						div{
							display: flex;
							align-items: center;
							cursor:pointer;
							p{
								font-size: 0.14rem;
							}
							.p2{
								color:#9a86db;
							}
							.p1{
								color:#1A2533;
							}
						}
					}
					.nei_box{
						width:6.6rem;
						margin: 0 auto;
						max-height: 2.4rem;
						overflow:auto;
						// overflow-y: hidden;
						overflow-y: scroll;
						&::-webkit-scrollbar {
							width: 0.19rem;
							height: 0;
						}

						&::-webkit-scrollbar-thumb {
							border-radius: 0.12rem;
							border: 0.06rem solid rgba(0, 0, 0, 0);
							box-shadow: 0.08rem 0 0 #A5ADB7 inset;
						}

						&::-webkit-scrollbar-thumb:hover {
							box-shadow: 0.08rem 0 0 #4A4A4A inset;
						}
						.liebiao{
							width:6.4rem;
							margin: 0 auto;
							ul{
								li{
									display: flex;
									height:0.3rem;
									justify-content: space-between;
									align-items: center;
									color:#1A2533;
									font-size: 0.12rem;
									padding-left: 0.1rem;
									cursor:pointer;
									&:hover{
										background: #eee;
									}
									.img1{
										height:0.11rem;
										width:0.2rem;
									}
									.img2{
										height:0.13rem;
										width:0.13rem;
										margin: 0 0.1rem;
									}
									input{
										-webkit-appearance: none;
										width: 0.18rem!important;
										height: 0.17rem!important;
										display: inline-block;
										margin: -0.03rem 0.05rem 0 0!important;
										cursor: pointer;
										vertical-align: middle;
										background:url(../assets/noccc.png) no-repeat;
										&:checked {
											background: url(../assets/okblue.png) no-repeat;
										}
										outline-color:none;
										outline-style :none;
										outline-width:none;
									}
								}
							}
						}
					}
				}
			}
			.foote{
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 0.21rem;
				p{
					margin: 0 0.23rem;
					height:0.43rem;
					width:1.36rem;
					border-radius: 0.02rem;
					font-size: 0.14rem;
					line-height: 0.43rem;
					text-align: center;
					font-weight: bold;
					cursor:pointer;
				}
				.queding{
					color:#fff;
					background: #9a86db;
				}
				.quxiao{
					color: #888888;
					border:0.01rem solid #888888;
					background: #fff;
				}
			}
		}
	}
}
</style>
